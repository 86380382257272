import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import * as S from "../components/SuccessAndNotFound/styled";

const PrivacyPage = () => (
  <Layout>
    <SEO title="Política de privacidade" />
    <S.SectionTextWrapper className="md">
      <h1>Política de Privacidade</h1>
      <h2>SEÇÃO 1 – O QUE FAREMOS COM ESTA INFORMAÇÃO?</h2>
      <p>Quando você acessa nosso site, recebemos automaticamente o protocolo de internet do seu computador, endereço de IP, a fim de obter informações que nos ajudam a aprender sobre seu navegador e sistema operacional.</p>
      <p>Email Marketing será realizado apenas caso você permita. Nestes e-mails você poderá receber notícia sobre nossa empresa, novos produtos e outras atualizações.</p>
      <h2>SEÇÃO 2 – CONSENTIMENTO</h2>
      <p>Como vocês obtêm meu consentimento?</p>
      <p>Se pedimos por suas informações pessoais por uma razão secundária, como marketing, vamos lhe pedir diretamente por seu consentimento, ou lhe fornecer a oportunidade de dizer não.</p>
      <p>E caso você queira retirar seu consentimento, como proceder?</p>
      <p>Se após você nos fornecer seus dados, você mudar de ideia, você pode retirar o seu consentimento para que possamos entrar em contato, para a coleção de dados contínua, uso ou divulgação de suas informações, a qualquer momento, entrando em contato conosco em marketing@esx.com.br</p>
      <h2>SEÇÃO 3 – DIVULGAÇÃO</h2>
      <p>Podemos divulgar suas informações pessoais caso sejamos obrigados pela lei para fazê-lo ou se você violar nossos Termos de Serviço.</p>
      <h2>SEÇÃO 4 – SERVIÇOS DE TERCEIROS</h2>
      <p>No geral, os fornecedores terceirizados usados por nós irão apenas coletar, usar e divulgar suas informações na medida do necessário para permitir que eles realizem os serviços que eles nos fornecem.</p>
      <p>Entretanto, certos fornecedores de serviços terceirizados, tais como hospedagem de serviços, armazenamento, segurança, têm suas próprias políticas de privacidade com respeito à informação que somos obrigados a fornecer para eles de suas transações relacionadas com esses items.

</p>
      <p>Para esses fornecedores, recomendamos que você leia suas políticas de privacidade para que você possa entender a maneira na qual suas informações pessoais serão usadas por esses fornecedores.</p>
      <p>Em particular, lembre-se que certos fornecedores podem ser localizados em ou possuir instalações que são localizadas em jurisdições diferentes que você ou nós. Assim, se você quer continuar com uma transação que envolve os serviços de um fornecedor de serviço terceirizado, então suas informações podem tornar-se sujeitas às leis da(s) jurisdição(ões) nas quais o fornecedor de serviço ou suas instalações estão localizados.</p>
      <p>Como um exemplo, se você está localizado no Canadá e sua transação é processada por um sistema de hospedagem Estados Unidos, então suas informações pessoais usadas para completar aquela transação podem estar sujeitas a divulgação sob a legislação dos Estados Unidos, incluindo o Ato Patriota.</p>
      <h2>SEÇÃO 5 – SEGURANÇA</h2>
      <p>Para proteger suas informações pessoais, tomamos precauções razoáveis e seguimos as melhores práticas da indústria para nos certificar que elas não serão perdidas inadequadamente, usurpadas, acessadas, divulgadas, alteradas ou destruídas.

</p>
      <p>Se você nos fornecer as suas informações pessoais, essa informação é criptografada usando tecnologia “secure socket layer” (SSL) e armazenada com uma criptografia AES-256. Embora nenhum método de transmissão pela Internet ou armazenamento eletrônico é 100% seguro, nós seguimos todos os requisitos da PCI-DSS e implementamos padrões adicionais geralmente aceitos pela indústria.</p>
      <h2>SEÇÃO 6 – ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</h2>
      <p>Reservamos o direito de modificar essa política de privacidade a qualquer momento, então por favor, revise-a com frequência. Alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação no site. Se fizermos alterações de materiais para essa política, iremos notificá-lo aqui que eles foram atualizados, para que você tenha ciência sobre quais informações coletamos, como as usamos, e sob que circunstâncias, se alguma, usamos e/ou divulgamos elas.</p>
      <p>Se nossa loja for adquirida ou fundida com outra empresa, suas informações podem ser transferidas para os novos proprietários para que possamos continuar a vender produtos para você.</p>
      <p><strong>esx ® 2020 – Todos os Direitos Reservados.</strong></p>
    </S.SectionTextWrapper>

  </Layout>
);

export default PrivacyPage;
